import React, {Component} from 'react';
import './list-details.css';
import Grid from "@material-ui/core/Grid";
import iconPhone from './img/image_phone.png';
import arrow from './img/navigate_next.png';
import appleIcon from './img/appleIcon.png';
import androidIcon from './img/androidIcon.png';
import phone from "../list-details-specifications/img/phone.png";

export default class ListDetails extends Component {
    render() {
        return (
            <div className='list-details-component' id='application'>
                <Grid container direction="row" justify="center" alignItems="center" className='list-details'>
                    <Grid lg='8' container direction="column" justify="flex-start" alignItems="flex-start">
                        <Grid className='list-details-info-container'>
                        <Grid className='list-details-info-title'>
                                <h1 className='list-details-info-text'>Подсчет количества бревен по фото - это просто!</h1>
                            </Grid>

                            <Grid className='list-details-info'>
                                <span className='list-details-info-label'>Приложение абсолютно бесплатно, создано для развития технологии оценки
                                    <br/> объема древесины.
                                    Будем благодарны за активное использование приложения,
                                    <br/> обнаружение неточностей и за предложения по развитию функицонала!</span>
                            </Grid>
                            <Grid className='list-details-info-about-work'>
                                <Grid sm='12' container justify="center" alignItems="center">
                                    <Grid container justify="center" alignItems="center">
                                        <Grid className='list-details-specifications-left'>
                                            <Grid className='list-details-specifications-container' container direction="column" justify="flex-start" alignItems="flex-start">
                                                <h1 className='list-details-specifications-left-title'>
                                                    Как пользоваться приложением</h1>
                                                <span className='list-details-specifications-left-label'>1. Запустите приложение</span>
                                                <span className='list-details-specifications-left-label'>2. Сделайте фото или выберите снимок штабеля из галереи телефона</span>
                                                <span className='list-details-specifications-left-label'>3. Программа посчитает количество бревен автоматически!</span>
                                                <span className='list-details-specifications-left-label'>Все обработанные снимки будут доступны вам в разделе "Галерея"</span>
                                                <span className='list-details-specifications-left-label'>Напишите нам - на сколько точно происходит подсчёт и какие еще функции вы хотели бы выдеть в нашем приложении.</span>
                                            </Grid>
                                            <Grid container className='list-details-specifications-img-container'>
                                                <img className='list-details-specifications-left-img' src={phone} alt='three'/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid lg='4' container direction="column" justify="center" alignItems="center" className='list-details-download-app-container'>
                        <Grid className='list-details-download-app'>
                            <Grid className="download-picture-container">
                                <Grid container justify="center" alignItems="center" className='download-picture'>
                                    <img className='download-picture-img' src={iconPhone} alt='phone' />
                                </Grid>
                                <Grid container justify="center" alignItems="center" className='download-now-container'>
                                    <span className='download-now'>Скачай прямо сейчас!</span>
                                </Grid>
                                <Grid container direction="row" justify="space-evenly" alignItems="center" className='button-download' >
                                    <button className='phone-download'>
                                        <Grid  className='phone-icon'>
                                                    <img src={appleIcon} alt='phone'/>
                                        </Grid>
                                        <Grid container>
                                            <span className='phone-download-head'>Available on the</span>
                                            <span className='phone-download-title'>APPLE STORE</span>
                                        </Grid>
                                    </button>

                                    <button className='phone-download'>
                                        <Grid className='phone-icon'>
                                            <img src={androidIcon} alt='phone'/>
                                        </Grid>
                                        <Grid container>
                                            <span className='phone-download-head'>Available on the</span>
                                            <span className='phone-download-title'>GOOGLE PLAY</span>
                                        </Grid>
                                    </button>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container direction="row" justify="space-evenly" alignItems="center" id='from-developer'>
                            <Grid xs='4' container direction="column" justify="center" alignItems="center" className='statistical-information-container'>
                                <Grid>
                                    <span className='statistical-information'> 99% </span>
                                </Grid>
                                <Grid>
                                    <span className='statistical-information-label'> Точность подсчета </span>
                                </Grid>
                            </Grid>
                            <Grid xs='4' container direction="column" justify="center" alignItems="center" className='statistical-information-container'>
                                <Grid>
                                   <span className='statistical-information'> 80% </span>
                                </Grid>
                                <Grid>
                                   <span className='statistical-information-label'> Экономия времени </span>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>


                {/*<div className='list-details-info'>*/}
                {/*    <div className='list-details-info-title'>*/}
                {/*        <h1>Подсчет количества бревен по <br/> фото - это просто!</h1>*/}
                {/*    </div>*/}

                {/*    <div className='list-details-info-label'>*/}
                {/*        <span>Приложение абсолютно бесплатно, создано для развития технологии оценки*/}
                {/*            <br/> объема древесины.*/}
                {/*            Будем благодарны за активное использование приложения,*/}
                {/*            <br/> обнаружение неточностей и за предложения по развитию функицонала!</span>*/}
                {/*    </div>*/}
                {/*    <div className='list-details-info-about-work'>*/}
                {/*        <span>Как это работает</span>*/}
                {/*        <label><img src={arrow} alt='phone' /></label>*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/*<div className='list-details-download-app'>*/}
                {/*    <div className="mdc-card mdc-card--outlined">*/}
                {/*        <div className='download-picture'>*/}
                {/*            <img src={iconPhone} alt='phone' />*/}
                {/*        </div>*/}
                {/*        <div className='download-now'>*/}
                {/*            <span>Скачай прямо сейчас!</span>*/}
                {/*        </div>*/}
                {/*        <div className='button-download'>*/}

                {/*            <a href='#'>*/}
                {/*                <button className="mdc-button mdc-button--raised button-download-apple">*/}
                {/*                    <span className="mdc-button__ripple"></span>*/}
                {/*                    <img src={appleIcon} alt='phone' />*/}
                {/*                    <div>*/}
                {/*                        <div>*/}
                {/*                            <span className="mdc-button__label phone-title">Available on the</span>*/}
                {/*                        </div>*/}
                {/*                        <div>*/}
                {/*                            <span className="mdc-button__label phone-label">APPLE STORE</span>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </button>*/}
                {/*            </a>*/}

                {/*            <a href='#'>*/}
                {/*                <button className="mdc-button mdc-button--raised button-download-android">*/}
                {/*                    <span className="mdc-button__ripple"></span>*/}
                {/*                    <img src={androidIcon} alt='phone' />*/}
                {/*                    <div>*/}
                {/*                        <div>*/}
                {/*                            <span className="mdc-button__label phone-title">Android App on</span>*/}
                {/*                        </div>*/}
                {/*                        <div>*/}
                {/*                            <span className="mdc-button__label phone-label">GOOGLE PLAY</span>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </button>*/}
                {/*            </a>*/}

                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        )
    }
};
