import React, {Component} from 'react';
import icon from './img/logo.png';
import Grid from "@material-ui/core/Grid";
import './footer.css';

import fb from './img/f.png';
import ins from './img/ins.png';
import odn from './img/ODN.png';
import vk from './img/vk.png';

export default class Footer extends Component {

    render() {

        // const currentYear = new Date().getFullYear();
        // console.log(currentYear);

        return (
                 <div>
                    <hr className='footer-component-hr'/>
                    <Grid className='footer-component'>
                        <Grid className='footer-up'>
                            <Grid className='footer-logo'>
                                <img src={icon} alt='error icon'/>
                                <span>LESNIK. ai</span>
                            </Grid>
                            <Grid className='footer-info'>
                                <span className='footer-info-label'><a href='https://docs.google.com/document/d/1POsRtrNadHKGs5b8blaEA15E6-AhjTOefGAebb8XAXk/edit?usp=sharing' className='footer-info-label-link'>Политика конфиденциальности</a></span>
                            </Grid>
                        </Grid>
                        <Grid className='footer-down'>
                            <Grid className='footer-copyright' container justify="space-between" alignItems="center">
                                <span className='footer-info-label'>
                                    <a href='https://mmtr.ru/' className='footer-share-link-copyright'>© ООО «ММТР технологии» 2020-2021.</a>
                                </span>
                                <span className='footer-info-label'>Все права защищены.</span>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
        )
    }
};
