import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import './list-details-specifications.css'
import ellipse from './img/Ellipse.png';
import vector from "./img/Vector.png";
import phone from "./img/phone.png";


export default class ListDetailsSpecifications extends Component {
    render() {

        return(
            <div className='list-details-specifications-component'>
                <Grid container direction="row" justify="space-evenly" alignItems="flex-start">
                    <Grid sm='5' container justify="center" alignItems="center">
                        <Grid container justify="center" alignItems="center">
                            <Grid className='list-details-specifications-left'>
                                <Grid className='list-details-specifications-container' container direction="column" justify="flex-start" alignItems="flex-start">
                                    <h1 className='list-details-specifications-left-title'>
                                        Поговорим о характеристиках?</h1>
                                    <span className='list-details-specifications-left-label'>Что насчет текста, который будет что-то рассказывать и чтобы все такие вау, это круто!
                                   Текст тексттексттекст тексттекст текст текст текст текст текст текст текст тексттексттексттексттексттекст
                                     текст текст текст текст текст текст
                                   текст текст текст текст текст тексттексттексттексттекст</span>
                                </Grid>
                                <Grid container>
                                    <img className='list-details-specifications-left-img' src={phone} alt='three'/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid sm='5' container direction="column" justify="space-evenly" alignItems="center">
                        <Grid container direction="column" justify="center" alignItems="center" className='list-details-specifications-right-up'>
                            <img src={ellipse} alt='three'/>
                            <h1 className='list-details-specifications-right-title'>Точные данные</h1>
                            <span className='list-details-specifications-right-label'>Быстрый подсчет. <br/> Без усилий. </span>
                        </Grid>

                        <Grid container direction="column" justify="center" alignItems="center" className='list-details-specifications-right-down'>
                            <img className='list-details-specifications-right-img' src={vector} alt='three'/>
                            <h1 className='list-details-specifications-right-title' >Мощные данные</h1>
                            <span className='list-details-specifications-right-label'>В деле.</span>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }
}
