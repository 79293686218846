import React, {Component} from 'react';
import Header from "../header";
import Footer from "../footer";
import List from "../list";

import './app.css';

export default class App extends Component {

    render() {
        return (
            <div className='app-component'>
                <Header/>
                <List/>
                <Footer/>
            </div>
        );
    }
};