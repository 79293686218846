import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid";
import './list-details-app.css';
import pyramid from './img/pyramid.png';
import pyramid2 from './img/pyramid2.png';
import icon from './img/icon.png';
import done from './img/done.png';
import castle from './img/castle.png';

export default class ListDetailsApp extends Component {
    render() {
        return (
            <div className='list-detail-app-component' id='from-developer-mobile'>
                <Grid container direction="column" justify="flex-start" alignItems="center">
                    <Grid container direction="row" justify="center" alignItems="flex-end">
                        <Grid lg='12' container direction="row" justify="center" alignItems="center"  className='from-the-developers-container'>
                            <Grid container direction="row" justify="center" alignItems="center" className='from-the-developers'>
                                <Grid lg='7' container direction="row" justify="space-around" alignItems="flex-start">
                                    <Grid xs='5' sm='8' md='8' container direction="row" justify="center" alignItems="center">
                                        <img className='from-the-developers-img pyramid-desktop' src={pyramid} alt='pyramid'/>
                                        <img className='from-the-developers-img pyramid-mobile' src={pyramid2} alt='pyramid'/>
                                    </Grid>
                                    <Grid xs='7' sm='4' md='4' container direction="column" justify="center" alignItems="center" className='from-the-developers-img-info'>
                                        <Grid container direction="row" className='pyramid-label-1'>
                                            <Grid xs='12' className='pyramid-label' container direction='row' justify="center" alignItems="center">
                                                {/*<div className='circle'/> */}
                                                <Grid xs='2' className='icon-figure'><img className=' icon-figure icon-figure-done' src={done} alt='done'/></Grid>
                                                <Grid xs='8'>Подсчет количества бревен</Grid>
                                            </Grid>
                                        </Grid>


                                        <Grid container direction="row" className='pyramid-label-2'>
                                            <Grid xs='12' className='pyramid-label' container direction='row' justify="center" alignItems="center">
                                                {/*<div className='circle'/> */}
                                                <Grid xs='2' className='icon-figure castle'><img className='icon-figure castle' src={castle} alt='done'/></Grid>
                                                <Grid xs='8'>Расчет радиуса бревен</Grid>
                                            </Grid>
                                        </Grid>


                                        <Grid container direction="row" className='pyramid-label-3'>
                                            <Grid xs='12' className='pyramid-label' container direction='row' justify="center" alignItems="center">
                                                {/*<div className='circle'/> */}
                                                <Grid xs='2' className='icon-figure castle'><img className='icon-figure castle' src={castle} alt='done'/></Grid>
                                                <Grid xs='8'>Вычисление объема древесины</Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid lg='5' container direction="column"  justify="flex-start" className='from-the-developers-right-container'>

                                    <Grid container direction="row" justify="space-between" alignItems="flex-start" className='from-the-developers-head'>
                                        <Grid xs='4' md='5'>
                                            {/*<Grid className='from-the-developers-right'>*/}
                                            {/*    Это интересно*/}
                                            {/*</Grid>*/}
                                        </Grid>
                                        <Grid xs='7' md='6' container justify="flex-end" alignItems="flex-end" className='from-the-developers-right-icon'>
                                            <img className='from-the-developers-right-icon-image' src={icon} alt='icon'/>
                                        </Grid>
                                    </Grid>

                                    <Grid container direction="column"  justify="flex-start" className='from-the-developers-right-info-container'>
                                        <Grid className='from-the-developers-right-info'>
                                            <h1 className='from-the-developers-right-title'>
                                                От разработчиков
                                            </h1>
                                        </Grid>

                                        <Grid className='from-the-developers'>
                                            <Grid className='from-the-developers-right-info'>
                                                <span className='from-the-developers-right-label'>В ближайших версиях мы планируем:</span>
                                            </Grid>

                                            <Grid className='from-the-developers-right-info'>
                                                <span className='from-the-developers-right-label developers-info'>- автоматическое определение диаметра бревен</span>
                                            </Grid>

                                            <Grid className='from-the-developers-right-info developers-info'>
                                                <span className='from-the-developers-right-label'>- вычисление объема древесины по фото штабеля или лесовоза</span>
                                            </Grid>

                                            <Grid className='from-the-developers-right-info'>
                                                <span className='from-the-developers-right-label'> Приложение использует сделанные фото для дальнейшего обучения алгоритмов распознования и
                                                    чем больше вы используете приложение, тем точнее оно справляется с подсчетом древесины.
                                                </span>
                                            </Grid>

                                            <Grid className='from-the-developers-right-info'>
                                                <span className='from-the-developers-right-label'>
                                                    Разработчики будут вам очень признательны за отзывы и предложения по улучшению функционала приложения.
                                                </span>
                                            </Grid>

                                            <Grid className='from-the-developers-right-info'>
                                                <span className='from-the-developers-right-label'>В текущей версии приложение требует подключения к интернет для работы.</span>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </div>
        )
    }
}
