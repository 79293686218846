import React, {Component} from 'react';
import ListDetails from "../list-details";
import ListDetailsApp from "../list-details-app";
import ListDetailsSpecifications from "../list-details-specifications";
import ListForm from "../list-form";
import './list.css';

export default class List extends Component {

    render() {
        return (
            <div className='list-component'>
                <ListDetails/>
                <ListDetailsApp/>
                {/*<ListDetailsSpecifications/>*/}
                <ListForm/>
            </div>
        );
    }
};
