import React, {Component} from 'react';
import icon from './logo.png';
import './header.css';
import Grid from "@material-ui/core/Grid";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';

// export default class Header extends Component {
export default function Header() {
    // render() {

        const [anchorEl, setAnchorEl] = React.useState(null);

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        return (
            <Grid className='header-component'>
                <Grid container direction="row" justify="space-between" alignItems="center" className='navbar-left'>
                    <Grid xs='3' container direction="row" justify="flex-start" alignItems="center">
                        <img src={icon} alt='error icon'/>
                        <span className='header-component-logo-label'>LESNIK. ai</span>
                    </Grid>

                    <Grid xs='9' container direction="row" justify="flex-end" alignItems="center" className='navbar-right'>

                        <Grid className='navbar-mobile'>
                            <Button className='button-menu' aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                                 <Icon>menu</Icon>
                            </Button>
                            <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                                <MenuItem onClick={handleClose}> <a href='#application' className='header-link-mobile'>Приложение</a></MenuItem>
                                <MenuItem onClick={handleClose}> <a href='#from-developer-mobile' className='header-link-mobile'>От разработчиков</a></MenuItem>
                                <MenuItem onClick={handleClose}> <a href='#write-to-us' className='header-link-mobile'>Напишите нам</a></MenuItem>
                            </Menu>
                        </Grid>

                        <Grid className='navbar-desktop'>
                            <span className='header-link-label'>
                                <a href='#application' className='header-link'>Приложение</a>
                            </span>

                            <span className='header-link-label'>
                                <a href='#from-developer' className='header-link'>От разработчиков</a>
                            </span>

                            <span className='header-link-label'>
                                <a href='#write-to-us' className='header-link'>Напишите нам</a>
                            </span>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid>
                    <hr className='header-component-hr'/>
                </Grid>
            </Grid>
        );
    }
// };
