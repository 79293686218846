import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Icon from '@material-ui/core/Icon';
import axios from "axios";
import {Formik, Form, Field} from "formik";

import './list-form.css';

export default class ListForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            surname: null,
            phone: null,
            company: null,
            email: '',
            message: '',
            submit: '',
            selectOfTheCompany: '',

            submitStatus: false,
            checkRepeatSubmit: true,
            doneSubmit: 'Ваше сообщение успешно отправлено',
        };

        this.checkName = true;
        this.checkEmail = true;
        this.checkMessage = true;
        this.handleChange = this.handleChange.bind(this);
        this.textInputName = React.createRef();
        this.textInputEmail = React.createRef();
        this.textInputMessage = React.createRef();
        this.focusTextInput = this.focusTextInput.bind(this);
    }

    focusTextInput() {
        // Explicitly focus the text input using the raw DOM API
        // Note: we're accessing "current" to get the DOM node
        setTimeout(() => {
            if (this.checkName === true) {
                this.textInputName.current.focus();
            } else if (this.checkEmail === true) {
                this.textInputEmail.current.focus();
            } else if (this.checkMessage === true) {
                this.textInputMessage.current.focus();
            }
        },100)

    }


    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    };

    validateUsername(value) {
        let error;
        if (value === '') {
            error = 'Заполните поле имя';
        }
        return error;
    }

    validateEmail(value) {
        let error;
        if (!value) {
            error = 'Заполните поле E-mail';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
            error = 'введите корректный E-mail address';
        }
        return error;
    }

    validateSelectOfTheCompany(value) {
        let error;
        if (value === 'Select') {
            error = 'Выберите тип деятельности компании';
        }
        return error;
    }

    validateMessage(value) {
        let error;
        if (value === '') {
            error = 'Заполните поле сообщение';
        }
        return error;
    }

    render() {

        const currencies = [
            {
                value: 'Select',
                label: 'Выберите тип деятельности',
            },
            {
                value: 'Other',
                label: 'Другое',
            },
            {
                value: 'Lumberjack',
                label: 'Заготовка древесины',
            },
            {
                value: 'Transporter',
                label: 'Транспортировка',
            },
            {
                value: 'WoodProcessing ',
                label: 'Деревообработка',
            }
        ];

        const errorValidFormField = 'list-form-input error-valid pulse';
        const validFormField = 'list-form-input';

        const errorValidFormFieldMessage = 'list-form-outlined error-valid pulse';
        const validFormFieldMessage = 'list-form-outlined';

        return(
            <Grid container justify="center" alignItems="center" className='list-form-component' id='write-to-us'>
                <Formik
                    initialValues={{
                        username: '',
                        surname: '',
                        email: '',
                        message: '',
                        phone: null,
                        company: null,
                        selectOfTheCompany: 'Select'
                    }}
                    onSubmit={values => {
                        const fullName = `${values.name} ${values.surname}`;

                        const JSON = {
                            name: fullName,
                            phone: values.phone,
                            email: values.email,
                            text: values.message,
                            companyType: values.selectOfTheCompany,
                            companyName: values.company
                        };

                        if (this.state.checkRepeatSubmit) {
                            axios.post('http://countlogsdataapi.mmtr.ru/api/Feedback/', JSON).then(res => {
                                if (res.status === 200) {
                                    this.setState({
                                        submitStatus: true
                                    })
                                }
                            });
                        }

                        this.setState({
                            checkRepeatSubmit: false
                        });
                    }}
                >
                    {({ errors, touched, isValidating }) => (
                        <Form className='form-container'>
                            <Grid container className='list-form-component-container' direction="column" justify="center"
                                  alignItems="center">
                                <Grid container direction="row" justify="flex-start" alignItems="flex-start" className='list-form-input-title'>
                                    <h1 className='list-form-head'>Обратная связь</h1>
                                </Grid>
                                <Grid className='list-form-input-control' container direction="row" justify="space-around" alignItems="center">
                                    <Grid sm='3' container direction="column" justify="flex-start" alignItems="flex-start">
                                        <Grid container className='list-form-input-title'>
                                    <span className='list-form-title'>Имя <label className='list-form-required'>*</label>
                                        {errors.username && touched.username && <label className='list-form-required'>{errors.username}</label>}
                                    </span>
                                        </Grid>
                                        <Grid container className='list-form-input-container'>
                                            <Field name="username" innerRef={this.textInputName} className={errors.username ? errorValidFormField : validFormField } placeholder='Введите имя'
                                                   validate={this.validateUsername} />
                                                    {errors.username ? this.checkName = true : this.checkName = false}
                                        </Grid>
                                    </Grid>
                                    <Grid sm='3' container direction="column" justify="flex-start" alignItems="flex-start">
                                        <Grid container className='list-form-input-title'>
                                            <span className='list-form-title'> Фамилия </span>
                                        </Grid>
                                        <Grid container className='list-form-input-container'>
                                            <Field name="surname" className='list-form-input' placeholder='Введите фамилию' value={this.state.surname}/>
                                        </Grid>
                                    </Grid>
                                    <Grid sm='3' container direction="column" justify="flex-start" alignItems="flex-start">
                                        <Grid container className='list-form-input-title'>
                                            <span className='list-form-title'> Телефон</span>
                                        </Grid>
                                        <Grid container className='list-form-input-container'>
                                            <Field name="phone" className='list-form-input' placeholder='Введите номер телефона' value={this.state.phone}/>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid className='list-form-input-control' container direction="row" justify="space-around" alignItems="center">
                                    <Grid sm='3' container direction="column" justify="flex-start" alignItems="flex-start">
                                        <Grid container className='list-form-input-title'>
                                            <span className='list-form-title'> Компания</span>
                                        </Grid>
                                        <Grid container className='list-form-input-container'>
                                            <Field name="company" className='list-form-input' placeholder='Введите компанию' value={this.state.companyName}/>
                                        </Grid>
                                    </Grid>

                                    <Grid sm='7' container direction="column" justify="flex-start" alignItems="flex-start">
                                        <Grid container className='list-form-input-title'>
                                  <span className='list-form-title'> Основная деятельность компании <label className='list-form-required'>*</label>
                                      {errors.selectOfTheCompany && touched.selectOfTheCompany && <label className='list-form-required'>{errors.selectOfTheCompany}</label>}
                                  </span>
                                        </Grid>
                                        <Grid container className='list-form-input-container'>
                                            <Grid className='list-form-select-container list-form-input-container' >
                                                <Field name="selectOfTheCompany" as="select" className={errors.selectOfTheCompany ? errorValidFormField : validFormField }  validate={this.validateSelectOfTheCompany} >
                                                    {currencies.map((option) => (
                                                        <option key={option.value} value={option.value}>
                                                            {option.label}
                                                        </option>
                                                    ))}
                                                </Field>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid className='list-form-input-control' container direction="row" justify="center" alignItems="center">
                                    <Grid sm='11' container direction="column" justify="flex-start" alignItems="flex-start">
                                        <Grid container className='list-form-input-title'>
                                  <span className='list-form-title'>E-mail <label className='list-form-required'>*</label>
                                      {errors.email && touched.email && <label className='list-form-required'>{errors.email}</label>}
                                  </span>
                                        </Grid>
                                        <Grid container className='list-form-input-container'>
                                            <Field innerRef={this.textInputEmail} className={errors.email ? errorValidFormField : validFormField } name="email" placeholder='Введите E-mail' validate={this.validateEmail} />
                                            {errors.email ? this.checkEmail = true : this.checkEmail = false}
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid className='list-form-input-control' container direction="row" justify="center" alignItems="center">
                                    <Grid sm='11' container direction="column" justify="flex-start" alignItems="flex-start">
                                        <Grid container className='list-form-input-title'>
                                  <span className='list-form-title'>Сообщение <label className='list-form-required'>*</label>
                                      {errors.message && touched.message && <label className='list-form-required'>{errors.message}</label>}
                                  </span>
                                        </Grid>
                                        <Grid container className='list-form-input-container'>
                                            <Field name="message" innerRef={this.textInputMessage} className={errors.message ? errorValidFormFieldMessage : validFormFieldMessage }
                                                   as='textarea' placeholder='Введите сообщение' validate={this.validateMessage} />
                                            {errors.message ? this.checkMessage = true : this.checkMessage = false}
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid container direction="row" justify="flex-end" alignItems="flex-end">

                                    <Grid container justify="center" alignItems="center">
                                        {this.state.submitStatus ? <label className='list-form-done-submit'>{this.state.doneSubmit}</label> : ''}
                                    </Grid>

                                    <Grid className='list-form-button-control' sm='11' container direction="row" justify="flex-end" alignItems="flex-end">
                                        <Button className='list-form-button submit-button' variant="outlined" color="primary" type='submit' onClick={this.focusTextInput} >
                                            <span className='submit-button-title'> {<Icon className='submit-button-icon'>message</Icon>} Отправить </span>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Grid>
        )
    }
}
